import './App.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import "quill/dist/quill.bubble.css";



import { BrowserRouter, Route, RouterProvider, Routes, createBrowserRouter, useLocation } from 'react-router-dom';

import Headers from './components/app_Visiteurs/headers/Headers';
import Mot_du_President from './components/app_Visiteurs/sections/Mot_president/Mot_du_President';
import Commissiondelaconcurrence from './components/app_Visiteurs/sections/Presentation/CommissionConcurrennce';
import Commissaires from './components/app_Visiteurs/sections/Presentation/Commissaires';
import Missions from './components/app_Visiteurs/sections/Presentation/Missions';
import Secretariat from './components/app_Visiteurs/sections/Presentation/Secretariat';
import Organigramme from './components/app_Visiteurs/sections/Presentation/Organigramme';
import CommentSaisirCommission from './components/app_Visiteurs/sections/Fonctionnement/CommentFaireSainse';
import FormulaireSaisine from './components/app_Visiteurs/sections/Fonctionnement/FormulaireSaisine';
import QuiPeutSaireCommission from './components/app_Visiteurs/sections/Fonctionnement/QuiPeutSaireCommission';
import Arretes from './components/app_Visiteurs/sections/textes-reglementaires/Arretes';
import Decrets from './components/app_Visiteurs/sections/textes-reglementaires/Decrets';
import Lois from './components/app_Visiteurs/sections/textes-reglementaires/Lois';
import Ordonnaces from './components/app_Visiteurs/sections/textes-reglementaires/Ordonnaces';
import Reglements from './components/app_Visiteurs/sections/textes-reglementaires/Reglements';
import AvisConsultatifs from './components/app_Visiteurs/sections/avis/AvisConsultatifs';
import AvisContentieux from './components/app_Visiteurs/sections/avis/AvisContentieux';
import JourneePorteOuverte from './components/app_Visiteurs/sections/activites/JourneePorteOuverte';
import CampagneSensibilisation from './components/app_Visiteurs/sections/activites/CampagneSensibilisation';
import Formations from './components/app_Visiteurs/sections/activites/Formations';
import Ateliers from './components/app_Visiteurs/sections/activites/Ateliers';
import Publications from './components/app_Visiteurs/sections/actualites/Publications';
import CommuniquePress from './components/app_Visiteurs/sections/actualites/CommuniquePress';
import GaleriePhoto from './components/app_Visiteurs/sections/actualites/GaleriePhoto';
import GalerieVideo from './components/app_Visiteurs/sections/actualites/GalerieVideo';
import ArchivesArticles from './components/app_Visiteurs/sections/actualites/ArchivesArticles';
import Contacts from './components/app_Visiteurs/sections/contact/Contacts';
import PagesEnCOnstructions from './components/app_Visiteurs/PagesEnCOnstructions';
import Footers from './components/app_Visiteurs/footers/Footers';
import Accueil from './components/app_Visiteurs/sections/Accueil/Accueil';
import ViewDocument from './components/app_Visiteurs/sections/textes-reglementaires/ViewDocument';
import ViewPhotos from './components/app_Visiteurs/sections/actualites/ViewPhotos';
import ArticleDetail from './components/app_Visiteurs/sections/actualites/ArticleDetail';
import PratiquesReprimes from './components/app_Visiteurs/sections/Fonctionnement/PratiquesReprimes';
import LoginAdmin from './components/app_admin/loginadmins/LoginAdmin';
import NavbarAdmin from './components/app_admin/AdminSections/NavBar/NavbarAdmin';
import DashboardAdmin from './components/app_admin/AdminSections/Dashboard/DashboardAdmin';
import Mot_president from './components/app_Visiteurs/sections/Accueil/SectionsAccueil/Mot_president';
import Mot_du_president from './components/app_admin/Presentation/Mot_du_president';
import Commission_concurrence from './components/app_admin/Presentation/Commission_concurrence';
import AdminCommissaires from './components/app_admin/Presentation/Commissaires';
import AdminSecretariat_General from './components/app_admin/Presentation/Secretariat_General';
import AdminPratiques_reprimes from './components/app_admin/Fonctionnement/Pratiques_reprimes';
import AdminComment_saisine from './components/app_admin/Fonctionnement/Comment_saisine';
import Qui_peut_saisineAdmin from './components/app_admin/Fonctionnement/Qui_peut_saisine';
import SaisineListeAdmin from './components/app_admin/Fonctionnement/SaisineListe';
import RegmentsAdmin from './components/app_admin/Text-reglementaire/Regments';
import OrdornancesAdmin from './components/app_admin/Text-reglementaire/Ordornances';
import DecretsAdmin from './components/app_admin/Text-reglementaire/Decrets';
import LoisAdmin from './components/app_admin/Text-reglementaire/Lois';
import ArretesAdmin from './components/app_admin/Text-reglementaire/Arretes';

import CommuniquePresseAdmin from './components/app_admin/Actualites/CommuniquePresse';
import Galerie_photoAdmin from './components/app_admin/Actualites/Galerie_photo';
import PublicationsAdmin from './components/app_admin/Actualites/Publications';
import Galerie_videoAdmin from './components/app_admin/Actualites/Galerie_video';
import Newsletter from './components/app_Visiteurs/sections/Accueil/SectionsAccueil/Newsletter';
import ListeAbonnesAdmin from './components/app_admin/Newsletters/ListeAbonnes';
import CreatePublications from './components/app_admin/Actualites/CreatePublications';
import Update_Pub from './components/app_admin/Actualites/Update_Pub';
import Voir_Publications from './components/app_admin/Actualites/Voir_Publications';
import CreateCommunique from './components/app_admin/Actualites/CommuniquePresses/CreateCommunique';
import VoirCommunique from './components/app_admin/Actualites/CommuniquePresses/VoirCommunique';
import Update_Communique from './components/app_admin/Actualites/CommuniquePresses/Update_Communique';
import Voir_Galerie from './components/app_admin/Actualites/GaleriePhotoGestion/Voir_Galerie';
import ModifierGalerie from './components/app_admin/Actualites/GaleriePhotoGestion/ModifierGalerie';
import CreateGalerie from './components/app_admin/Actualites/GaleriePhotoGestion/CreateGalerie';
import CreateVideo from './components/app_admin/Actualites/GalerieVideo/CrreateVideo';
import UpdateVideo from './components/app_admin/Actualites/GalerieVideo/UpdateVideo';
import VoirVideo from './components/app_admin/Actualites/GalerieVideo/VoirVideo';
import ActivitesAdmin from './components/app_admin/Activites/ActivitesAdmin';
import Administrateurs from './components/app_admin/Parametre/Administrateurs';
import CreateAdmins from './components/app_admin/Parametre/AdminsComponent/CreateAdmins';
import VoirDocument from './components/app_admin/Text-reglementaire/VoirDocument';
import UpdateDocument from './components/app_admin/Text-reglementaire/UpdateDocument';
import CreateDocument from './components/app_admin/Text-reglementaire/CreateDocument';
import TraiteUEMOA from './components/app_admin/Text-reglementaire/TraiteUEMOA';
import Directives from './components/app_admin/Text-reglementaire/Directives';
import Visiteur_trate_uemoa from './components/app_Visiteurs/sections/textes-reglementaires/Visiteur_trate_uemoa';
import Visiteur_DIrectives from './components/app_Visiteurs/sections/textes-reglementaires/Visiteur_DIrectives';
import AvisCOnsultatifsAdmin from './components/app_admin/Text-reglementaire/AvisCOnsultatifsAdmin';
import AvisContentieuxAdmin from './components/app_admin/Text-reglementaire/AvisContentieuxAdmin';
import Historiques from './components/app_Visiteurs/sections/Presentation/CommissionConcurrennce';
import Fonctionnement from './components/app_Visiteurs/sections/Presentation/Commissaires';
import NewActivite from './components/app_admin/Activites/NewActivite';
import DetailActivite from './components/app_admin/Activites/DetailActivite';
import VoirActivite from './components/app_Visiteurs/sections/activites/VoirActivite';
import FlashInfo from './components/app_admin/Parametre/FlashInfo/FlashInfo';
import Partenaires from './components/app_admin/Parametre/FlashInfo/Partenaires/Partenaires';
import ContactsAdmin from './components/app_admin/ContactAdmin/ContactsAdmin';
import ContactsAdminAdminDetail from './components/app_admin/ContactAdmin/VoirContact';
import SaineComponent from './components/app_admin/saine/SaineComponent';
import DetailPlainte from './components/app_admin/saine/DetailPlainte';
import PrivateRoute from './PrivateRoute';
import { useSelector } from 'react-redux';
import CreateContenuNewsleters from './components/app_admin/Newsletters/CreateContenuNewsleters';
import ScrollToTop from './components/ScrollTotOP';
import ProfilUser from './components/app_admin/Parametre/AdminsComponent/ProfilUser';
import ManuelProcedures from './components/app_Visiteurs/sections/Presentation/ManuelProcedures';
import GuideUtilisation from './components/app_Visiteurs/sections/Presentation/GuideUtilisation';
import DetailsCommunique from './components/app_Visiteurs/sections/actualites/ViewCommunique';



function App() {

  const isAuthenticated = useSelector((state) => !!state.user.access);
  return (
   
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
       
        <Route path='/'>
          <Route index element = { <Accueil />}></Route>
          <Route path='admin-login' element={<LoginAdmin/>}></Route>

          <Route path='public/nos_missions' element={<Missions />}></Route>
          <Route path='presentation'>
              <Route path='mot_du_president' element={<Mot_du_President />}></Route>
              <Route path='historique' element={<Historiques />}></Route>
              <Route path='fonctionnement' element={<Fonctionnement />}></Route>
              <Route path='missions' element={<Missions />}></Route>
              <Route path='secretariatgeneral' element={<Secretariat />}></Route>
              <Route path='organigrame' element={<Organigramme />}></Route>
              <Route path='guide-utilisation' element={<GuideUtilisation />}></Route>
              <Route path='manuel-procedure' element={<ManuelProcedures />}></Route>


          </Route>
          <Route path='nous-saisir'>
          <Route path='pratiques-reprimees' element={<PratiquesReprimes />}></Route>
            <Route path='comment-saisir-la-commission' element={<CommentSaisirCommission />}></Route>
            <Route path='qui-peut-saisir-lacommission' element={<QuiPeutSaireCommission />}></Route>
            <Route path='faire-une-saisine' element={<FormulaireSaisine />}></Route>
          </Route>

          <Route path='textes-reglementaires'>

            <Route path='cedeao'>
                <Route path='traite' element={<Visiteur_trate_uemoa />}></Route>
                <Route path='directives' element={<Visiteur_DIrectives />}></Route>
                <Route path='reglements' element={<Reglements />}></Route>
            </Route>

            <Route path='uemoa'>
                <Route path='traite' element={<Visiteur_trate_uemoa />}></Route>
                <Route path='directives' element={<Visiteur_DIrectives />}></Route>
                <Route path='reglements' element={<Reglements />}></Route>
            </Route>
            
            <Route path='decrets' element={<Decrets />}></Route>
            <Route path='lois' element={<Lois />}></Route>
            <Route path='ordonnaces' element={<Ordonnaces />}></Route>
            
            <Route path='arretes' element={<Arretes />}></Route>
            <Route path='doc/:id' element={<ViewDocument />}></Route>


            
          </Route>


          <Route path='avis'>
            <Route path='consultatifs' element={<AvisConsultatifs />}></Route>
            <Route path='contentieux' element={<AvisContentieux />}></Route>
          </Route>


          <Route path='activites'>
            <Route path='ateliers' element={<Ateliers />}></Route>
            <Route path='formations' element={<Formations />}></Route>
            <Route path='campagnesensibilisation' element={<CampagneSensibilisation />}></Route>
            <Route path='journeeporteouverte' element={<JourneePorteOuverte />}></Route>
            <Route path='voiractivite/:id' element={<VoirActivite />}></Route>
          </Route>

          <Route path='actualites'>
            <Route path='publication' element={<Publications />}></Route>
            <Route path='publication/:id' element={<ArticleDetail />}></Route>
            <Route path='communiquepresse' element={<CommuniquePress />}></Route>
            <Route path='communiquepresse/:id' element={<DetailsCommunique />}></Route>
            <Route path='galeriephoto' element={<GaleriePhoto />}></Route>
            <Route path='galeriephoto/:id' element={<ViewPhotos />}></Route>

            <Route path='galerievideo' element={<GalerieVideo />}></Route>
            <Route path='archivearticles' element={<ArchivesArticles />}></Route>
          </Route>

          <Route path='contact' element={<Contacts />}></Route>

          



          <Route path='admin/'>

            <Route path='parametre'  >
              <Route path='administrateur' element={isAuthenticated ? <Administrateurs/> : <LoginAdmin />} ></Route>
              <Route path='flashInfo' element={isAuthenticated ? <FlashInfo/> : <LoginAdmin />} ></Route>
              <Route path='partenaires'element={isAuthenticated ? <Partenaires/> : <LoginAdmin />}></Route>
              <Route path='slogan'element={isAuthenticated ? <Administrateurs/> : <LoginAdmin />} ></Route>
              <Route path='administrateur/create'element={isAuthenticated ? <CreateAdmins/> : <LoginAdmin />} ></Route>
              < Route path='administrateur/profil/' element={isAuthenticated ? <ProfilUser/> : <LoginAdmin />} ></Route>
            </Route>
           

            <Route path='dashboard'  element={isAuthenticated ? <DashboardAdmin/> : <LoginAdmin />}></Route>
            

            <Route path='presentation'>
              <Route path='mot_president' element={isAuthenticated ? <Mot_du_president/> : <LoginAdmin />} ></Route>
              {/* <Route path='commission_conncurrene' element={isAuthenticated ? <Commission_concurrence/> : <LoginAdmin />} ></Route>
              <Route path='commissaires' element={isAuthenticated ? <AdminCommissaires/> : <LoginAdmin />} ></Route>
              <Route path='secretariat_general' element={isAuthenticated ? <AdminSecretariat_General/> : <LoginAdmin />} ></Route> */}

            </Route>

            {/* <Route path='fonctionnement'>
              <Route path='pratiques_reprimes' element={isAuthenticated ? <AdminPratiques_reprimes/> : <LoginAdmin />} ></Route>
              <Route path='qui_peut_saisir_la_commission' element={isAuthenticated ? <Qui_peut_saisineAdmin/> : <LoginAdmin />} ></Route>
              <Route path='comment_saisir_la_commission' element={isAuthenticated ? <AdminComment_saisine/> : <LoginAdmin />} ></Route>
              <Route path='saisine' element={isAuthenticated ? <SaisineListeAdmin/> : <LoginAdmin />}></Route>

            </Route> */}

            <Route path='textes-reglementaires'>
              <Route path='document/:id' element={isAuthenticated ? <VoirDocument/> : <LoginAdmin />}></Route>
              <Route path='createdocument' element={isAuthenticated ? <CreateDocument/> : <LoginAdmin />} ></Route>
              <Route path='document/update/:id' element={isAuthenticated ? <UpdateDocument/> : <LoginAdmin />} ></Route>
              <Route path='traite' element={isAuthenticated ? <TraiteUEMOA/> : <LoginAdmin />} ></Route>
              <Route path='directives' element={isAuthenticated ? <Directives/> : <LoginAdmin />} ></Route>
              <Route path='reglements' element={isAuthenticated ? <RegmentsAdmin/> : <LoginAdmin />} ></Route>
              <Route path='ordonnaces' element={isAuthenticated ? <OrdornancesAdmin/> : <LoginAdmin />} ></Route>
              <Route path='decrets' element={isAuthenticated ? <DecretsAdmin/> : <LoginAdmin />} ></Route>
              <Route path='lois' element={isAuthenticated ? <LoisAdmin/> : <LoginAdmin />} ></Route>
              <Route path='arretes' element={isAuthenticated ? <ArretesAdmin/> : <LoginAdmin />} ></Route>
            </Route>

            <Route path='avis'>
              <Route path='avis_consultatifs' element={isAuthenticated ? <AvisCOnsultatifsAdmin/> : <LoginAdmin />} ></Route>
              <Route path='avis_contentieux' element={isAuthenticated ? <AvisContentieuxAdmin/> : <LoginAdmin />} ></Route>
            </Route>


            <Route path='activites'>
              <Route index element={isAuthenticated ? <ActivitesAdmin/> : <LoginAdmin />} ></Route>
              <Route path='new-activity' element={isAuthenticated ? <NewActivite/> : <LoginAdmin />} ></Route>
              <Route path='voir-activite/:id' element={isAuthenticated ? <DetailActivite/> : <LoginAdmin />} ></Route>
             
            </Route>

            <Route path='actualites'>
              <Route path='publications' element={isAuthenticated ? <PublicationsAdmin/> : <LoginAdmin />} ></Route>

              <Route path='communique' element={isAuthenticated ? <CommuniquePresseAdmin/> : <LoginAdmin />} ></Route>
              <Route path='communique/:id' element={isAuthenticated ? <VoirCommunique/> : <LoginAdmin />} ></Route>
              <Route path='communique/modifier/:id' element={isAuthenticated ? <Update_Communique/> : <LoginAdmin />} ></Route>
              <Route path='creationcommunique' element={isAuthenticated ? <CreateCommunique/> : <LoginAdmin />}></Route>

              <Route path='galerie-videos' element={isAuthenticated ? <Galerie_videoAdmin/> : <LoginAdmin />} ></Route>
              <Route path='galerie-videos/:id' element={isAuthenticated ? <VoirVideo/> : <LoginAdmin />} ></Route>
              <Route path='galerie-videos/create' element={isAuthenticated ? <CreateVideo/> : <LoginAdmin />} ></Route>
              <Route path='galerie-videos/modifier/:id' element={isAuthenticated ? <UpdateVideo/> : <LoginAdmin />} ></Route>

              <Route path='galerie-photo' element={isAuthenticated ? <Galerie_photoAdmin/> : <LoginAdmin />} ></Route>
              <Route path='galerie-photo/creer' element={isAuthenticated ? <CreateGalerie/> : <LoginAdmin />}></Route>
              <Route path='galerie-photo/:id' element={isAuthenticated ? <Voir_Galerie/> : <LoginAdmin />}></Route>
              <Route path='galerie-photo/modifier/:id' element={isAuthenticated ? <ModifierGalerie/> : <LoginAdmin />}></Route>

              <Route path='create_publications' element={isAuthenticated ? <CreatePublications/> : <LoginAdmin />}></Route>
              <Route path='update-publication/:id' element={isAuthenticated ? <Update_Pub/> : <LoginAdmin />}></Route>
              <Route path='publications/:id' element={isAuthenticated ? <Voir_Publications/> : <LoginAdmin />}></Route>

             

            </Route>

            <Route path='newsletters'>
              <Route index element={isAuthenticated ? <ListeAbonnesAdmin/> : <LoginAdmin />} ></Route>
              <Route path='create-contenu' element={isAuthenticated ? <CreateContenuNewsleters/> : <LoginAdmin />}></Route>
              
            </Route>

            <Route path='depot-de-plainte'>
              <Route index element={isAuthenticated ? <SaineComponent/> : <LoginAdmin />} ></Route> 
              <Route path='infos_sup/:id' element={isAuthenticated ? <DetailPlainte/> : <LoginAdmin />}></Route>
              
            </Route>

            <Route path='contacts'>
              <Route index element={isAuthenticated ? <ContactsAdmin/> : <LoginAdmin />} ></Route>
              <Route path='voir-contact-admin/:id' element={isAuthenticated ? <ContactsAdminAdminDetail/> : <LoginAdmin />} ></Route>
            </Route>

            <Route path='*'  element={isAuthenticated ? <DashboardAdmin/> : <LoginAdmin />}></Route>
            


          </Route>



          

          <Route path='*' element={<Accueil />}></Route>

        
        </Route>

      </Routes>
    </BrowserRouter>
     
    
  );
}

export default App;
