import React from 'react'

import coverImage from '../../../images/imagecover.png'
import { useNavigate } from "react-router-dom";
import { BackendEntete } from '../../../VariablesConfig';



const ArticleCommunique = ({ PublicationListe, DataListe, isArchive }) => {

    const navigate = useNavigate()

    
    const redirect = (idparams)=>{
        const id = btoa(idparams)
        navigate(`/actualites/communiquepresse/${id}`)
    }

    const ContenuHTML = ({ contenu }) => {
        return <div dangerouslySetInnerHTML={{ __html: contenu }} />;
      }
      
    return (
        <>

        {
            DataListe && (
                <div className='grid gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                {
                    DataListe.map((publication, idx) => (
                        <div key={idx} onClick={()=>redirect(publication.id)} className="relative bg-gray-200 rounded-lg overflow-hidden cursor-pointer">
                            <img
                                className="w-full h-64 object-cover object-center"
                                src={BackendEntete + publication.image_cover }
                                alt={publication.titre}
                            />
                            <div className={`${ isArchive ? "absolute inset-0 bg-gradient-to-b from-transparent to-zinc-900	 hover:to-teal-900 opacity-90" : "absolute inset-0 bg-gradient-to-b from-transparent to-teal-900 hover:to-orange-900 opacity-90"}`}></div>
                            <div className="absolute bottom-0 left-0 w-full px-6 pb-1 text-white">
                                <p className="font-bold">{publication.titre}</p>
                                <p className='text-sm text-teal-600  flex gap-1 items-stretch text-white font-bold' > <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9" cy="9" r="9" fill="#E6F6F4" />
                                        <path d="M12.56 8.08H5.28C5.126 8.08 5 8.206 5 8.36V12.56C5 13.022 5.378 13.4 5.84 13.4H12C12.462 13.4 12.84 13.022 12.84 12.56V8.36C12.84 8.206 12.714 8.08 12.56 8.08ZM7.52 12C7.52 12.154 7.394 12.28 7.24 12.28H6.68C6.526 12.28 6.4 12.154 6.4 12V11.44C6.4 11.286 6.526 11.16 6.68 11.16H7.24C7.394 11.16 7.52 11.286 7.52 11.44V12ZM9.48 12C9.48 12.154 9.354 12.28 9.2 12.28H8.64C8.486 12.28 8.36 12.154 8.36 12V11.44C8.36 11.286 8.486 11.16 8.64 11.16H9.2C9.354 11.16 9.48 11.286 9.48 11.44V12ZM9.48 10.04C9.48 10.194 9.354 10.32 9.2 10.32H8.64C8.486 10.32 8.36 10.194 8.36 10.04V9.48C8.36 9.326 8.486 9.2 8.64 9.2H9.2C9.354 9.2 9.48 9.326 9.48 9.48V10.04ZM11.44 10.04C11.44 10.194 11.314 10.32 11.16 10.32H10.6C10.446 10.32 10.32 10.194 10.32 10.04V9.48C10.32 9.326 10.446 9.2 10.6 9.2H11.16C11.314 9.2 11.44 9.326 11.44 9.48V10.04ZM12 5.84H11.3V5.56C11.3 5.252 11.048 5 10.74 5C10.432 5 10.18 5.252 10.18 5.56V5.84H7.66V5.56C7.66 5.252 7.408 5 7.1 5C6.792 5 6.54 5.252 6.54 5.56V5.84H5.84C5.378 5.84 5 6.218 5 6.68V6.96C5 7.114 5.126 7.24 5.28 7.24H12.56C12.714 7.24 12.84 7.114 12.84 6.96V6.68C12.84 6.218 12.462 5.84 12 5.84Z" fill="#008774" />
                                    </svg> {publication.date_creation}</p>
                            </div>
                            
                        </div>
                    ))
                }

            </div>
            )
        }

            
        </>


    )
}

export default ArticleCommunique

